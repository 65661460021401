import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es__header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es__header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es__header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es__header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/know_the_sun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/know_the_sun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/conozca-el-sol/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/sobre_o_sol/">PT</a>
                      </li>
                      <li>
                        <a href="/it/know_the_sun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/know_the_sun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="kts-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x337px_KnowTheSun.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/know_the_sun_960x405_topSmall.jpg"
                  />
                </div>
              </div>
            </section>
            <div className="kts-page container">
              <div className="kts-page__intro">
                <h1>CONOSCERE IL SOLE</h1>
                <h3>La nostra guida per esporsi al sole in tutta sicurezza</h3>
                <p>
                  Tutti i prodotti PIZ BUIN<sup>®</sup> contengono la nostra
                  protezione solare avanzata UVA/UVB per assicurarti sempre
                  un’efficace protezione dai raggi solari. Tuttavia, riteniamo
                  che non si può mai essere troppo sicuri dei potenziali effetti
                  che il sole può avere su di noi. Riportiamo di seguito
                  importanti informazioni sul sole e su come è possibile
                  goderselo al meglio e in maniera responsabile.
                </p>
              </div>
              <section id="content">
                <div className="innerContent">
                  <ul className="ktsBlocks">
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">Il sole</h2>
                        <p className="toggle__sub-title">
                          Raggi UVA e UVB sono termini familiari, ma che cosa
                          significano esattamente e quale effetto hanno su di
                          noi? Puoi scoprirlo qui.{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul id="container_thesun">
                        <li>
                          <div
                            id="ccm-layout-wrapper-5726"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-4-1"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-1 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-4-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  <h2>Radiazioni</h2>
                                  <p className="p1">
                                    Il sole fornisce alla terra energia vitale
                                    ed è il fattore principale nella
                                    determinazione delle condizioni climatiche e
                                    atmosferiche della terra. Il sole trasmette
                                    alla terra non solo la luce visibile ma
                                    anche radiazioni invisibili che possono
                                    avere un impatto negativo sulla salute, se
                                    ricevute in dosi troppo elevate.
                                  </p>
                                  <p className="p1">
                                    Le radiazioni solari sono composte da
                                    un'ampia varietà di raggi. Alcuni non
                                    riescono a raggiungere la terra perché
                                    vengono bloccati dallo strato di ozono. Le
                                    radiazioni che raggiungono la terra sono
                                    composte da raggi visibili, raggi infrarossi
                                    (IR) e raggi ultravioletti (UV). Le
                                    radiazioni UV si suddividono in raggi UVA,
                                    UVB e UVC, in base alla loro lunghezza
                                    d'onda. Anche i raggi UVC sono bloccati
                                    dallo strato di ozono.
                                  </p>
                                  <div
                                    id="blockStyle1683TheSunLayout1Cell162"
                                    className=" ccm-block-styles"
                                  >
                                    <img
                                      border="0"
                                      className="ccm-image-block"
                                      alt
                                      src="/assets/images/Radiation_graph_IT.png"
                                      width="640"
                                      height="380"
                                    />
                                  </div>
                                  <h2>Luce visibile</h2>
                                  <p>
                                    La luce visibile comprende tutti i colori
                                    che vediamo a occhio nudo.
                                  </p>
                                  <p> </p>
                                  <h2>Raggi infrarossi (IR)</h2>
                                  <p>
                                    I raggi infrarossi (IR) trasmettono
                                    l'energia termica, quella che ci permette di
                                    sentire il calore.
                                  </p>
                                  <p> </p>
                                  <h2>Raggi ultravioletti (UV)</h2>
                                  <p className="p1">
                                    I raggi ultravioletti (UV) sono invisibili e
                                    si suddividono in raggi UVA, UVB e UVC. I
                                    raggi UVA provocano danni a lungo termine
                                    alla pelle, tra cui cancro e invecchiamento
                                    cutaneo precoce, mentre i raggi UVB sono la
                                    causa principale delle scottature. I raggi
                                    UVA raggiungono la superficie terrestre in
                                    misura 10 volte maggiore rispetto ai raggi
                                    UVB. I raggi UVC vengono assorbiti
                                    dall'atmosfera terrestre e non hanno effetti
                                    sulla pelle.
                                  </p>
                                  <p className="p1">
                                    L'intensità delle radiazioni solari dipende
                                    da numerosi fattori, tra cui il periodo
                                    dell'anno, l'ora del giorno, la latitudine,
                                    l'altitudine, i riflessi sul terreno e le
                                    condizioni atmosferiche. Il sole è più
                                    intenso a mezzogiorno, ad esempio, in alta
                                    montagna e vengono assorbite meno radiazioni
                                    UV dagli strati più sottili dell'atmosfera.
                                  </p>
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="ccm-layout-wrapper-5727"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-8-3"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-3 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-8-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  &nbsp;
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1367TheSun83"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_01.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  <span className="orange">L'80%</span> dei
                                  raggi UV riesce a penetrare le nuvole
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1368TheSun91"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_02.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  La neve riflette fino{" "}
                                  <span className="orange">all'80%</span> di
                                  tutti i raggi UV
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1369TheSun92"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_03.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  <span className="orange">Il 60%</span> dei
                                  raggi UV è individuato tra le 10 del mattino e
                                  le 2 del pomeriggio
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1370TheSun93"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_04.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Le radiazioni UV aumentano del{" "}
                                  <span className="orange">4%</span> ogni{" "}
                                  <span className="orange">300</span> metri
                                  d'altitudine
                                </p>
                                <div> </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1372TheSun94"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_05.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  L'ombra può ridurre l'esposizione ai raggi UV
                                  del <span className="orange">50%</span> o più
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1373TheSun95"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_06.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  A mezzo metro sott'acqua, i raggi UV hanno
                                  ancora il <span className="orange">40%</span>{" "}
                                  di intensità rispetto alla superficie
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1374TheSun96"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_07.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  La sabbia bianca riflette fino al{" "}
                                  <span className="orange">15%</span> dei raggi
                                  UV
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle1375TheSun97"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_08.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  L'acqua riflette il{" "}
                                  <span className="orange">25%</span> circa
                                  delle radiazioni UV
                                </p>
                              </div>
                            </div>
                          </div>
                          <h2>Effetti del sole</h2>
                          <p>
                            Il sole rende possibile la vita sulla terra, ma può
                            anche avere effetti nocivi - soprattutto senza
                            un'adeguata protezione.
                          </p>
                          <p> </p>
                          <h2>Effetti immediati</h2>
                          <p>
                            <strong>Pigmentazione immediata</strong>
                            <br />
                            Il primo effetto dell'esposizione al sole legato
                            all'abbronzatura è noto come “pigmentazione
                            immediata”, ovvero un colorito che appare durante
                            l'esposizione e scompare entro 2 ore. Provocata dai
                            raggi UVA e dalla luce visibile, la pigmentazione
                            immediata non protegge dalle scottature.
                          </p>
                          <p>
                            <strong>
                              Pigmentazione persistente (Persistent pigment
                              darkening, PPD)
                            </strong>
                            <br />
                            La PPD appare durante l'esposizione solare e perdura
                            diversi giorni. Non è protettiva.
                          </p>
                          <p> </p>
                          <h2>Effetti ritardati</h2>
                          <p>
                            <strong>Scottature</strong>
                            <br />
                            Se non ci si protegge da alte quantità di raggi UV,
                            questi inizieranno a penetrare gli strati della
                            pelle. Alla fine, i vasi sanguigni al di sotto della
                            superficie cutanea si espanderanno e perderanno
                            fluidi, provocando rossore e infiammazioni. In
                            genere le scottature compaiono 2–6 ore dopo l'inizio
                            dell'esposizione, raggiungono la massima intensità
                            entro 24–36 ore e svaniscono dopo 3–5 giorni.
                          </p>
                          <p>
                            <strong>
                              Abbronzatura (pigmentazione ritardata)
                            </strong>
                            <br />
                            L'abbronzatura inizia 3–5 giorni dopo l'esposizione
                            al sole, quando speciali cellule cutanee che
                            prendono il nome di melanociti iniziano a
                            sintetizzare melanina, il pigmento abbronzante
                            naturale del corpo. La melanina viene quindi
                            trasferita alle cellule limitrofe che prendono il
                            nome di cheratinociti e sono il principale tipo di
                            cellula dell'epidermide, o strato superiore della
                            pelle. Diversamente dalla pigmentazione immediata,
                            la vera abbronzatura protegge dagli effetti nocivi
                            dei raggi UV. L'abbronzatura si affievolisce
                            progressivamente, ma è necessario ricordare che
                            anche le persone con un'abbronzatura naturale devono
                            applicare una protezione solare, dato che
                            l'abbronzatura non offre una protezione adeguata
                            dall'esposizione ai raggi UV.
                          </p>
                          <p> </p>
                          <h2>Effetti a lungo termine</h2>
                          <p>
                            <strong>Invecchiamento della pelle</strong>
                            <br />
                            L'esposizione solare ha un ruolo di primo piano nel
                            processo di invecchiamento della pelle. Fino al 90%
                            di tutti i segni visibili dell'invecchiamento
                            cutaneo possono essere attribuiti agli effetti
                            nocivi dell'esposizione al sole.
                          </p>
                          <p>
                            <strong>
                              Cancro alla pelle (melanoma maligno)
                            </strong>
                            <br />
                            Il cancro alla pelle, o melanoma maligno, sembra
                            essere causato da danni alla struttura del DNA delle
                            cellule cutanee esposte al sole – appena il 5% di
                            tutti i carcinomi si presentano su aree della pelle
                            che non vengono esposte. I danni a queste cellule
                            cutanee portano allo sviluppo di cellule degenerate
                            che, a loro volta, hanno come esito il cancro. In
                            Europa, il cancro alla pelle ha una prevalenza
                            decisamente maggiore al nord, dove la pelle delle
                            persone tende a contenere meno pigmentazione.
                          </p>
                          <p> </p>
                          <h2>Effetti accidentali</h2>
                          <p>
                            <strong>Intolleranze solari</strong>
                            <br />
                            Il tipo più comune di eruzione cutanea indotta dal
                            sole è l'esantema benigno da esposizione al sole
                            (BSLE). Particolarmente comune tra le giovani donne,
                            il BSLE si presenta con piccole papule che provocano
                            prurito sulla gola, la parte superiore del torace e
                            la parte posteriore degli avambracci. La condizione
                            si verifica entro qualche ora dopo un'intensa
                            esposizione al sole e svanisce in pochi giorni.
                          </p>
                          <div id="mountain" className=" ccm-block-styles">
                            <h2>Sole in montagna</h2>
                            <p>
                              <strong>
                                In montagna continui ad usare la stessa
                                protezione dell’estate?
                              </strong>
                            </p>
                            <p>
                              <strong>
                                <br />
                              </strong>
                            </p>
                            <p>
                              <strong>
                                <br />
                              </strong>
                            </p>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_01_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Per ogni <span className="orange">1,000</span>{" "}
                                metri di altitudine, l’intensità dei raggi
                                solari cresce fino al{" "}
                                <span className="orange">12%</span>
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_02_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                La neve fresca riflette le radiazioni UV{" "}
                                <span className="orange">5</span> volte di più
                                della sabbia asciutta sulla spiaggia
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_03_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Fino <span className="orange">all'80%</span>{" "}
                                delle radiazioni solari riesce a penetrare un
                                leggero strato di nuvole
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_04_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Il freddo, il vento e l’aria secca possono
                                ridurre l’idratazione della pelle del{" "}
                                <span className="orange">13%</span>
                              </p>
                            </div>
                          </div>
                          <div id="HTMLBlock891" className="HTMLBlock">
                            <div
                              style={{
                                clear: "both"
                              }}
                            />
                          </div>
                          <p>
                            <strong>Il risultato</strong>
                            <br />
                            La pelle si disidrata facilmente. Diventa tesa,
                            rossa e stressata dal sole. Difendere la pelle dal
                            freddo, dal vento e dal sole intenso richiede un
                            tipo particolare di protezione.
                          </p>
                          <p>
                            <strong>
                              <br />
                            </strong>
                          </p>
                          <p>
                            <strong>Usare la corretta protezione solare</strong>
                            <br />
                            In montagna, la tua pelle richiede un tipo di
                            protezione specifico. Per questo, è meglio usare uno
                            schermo solare che aiuti a difendere la tua pelle
                            dal freddo, dal vento e dal sole intenso.{" "}
                            <a href="/it/prodotti/mountain/">
                              PIZ BUIN<sup>®</sup> MOUNTAIN
                            </a>{" "}
                            aiuta a proteggere la tua pelle nelle condizioni di
                            montagna ad alta quota.
                          </p>
                          <p>
                            Per un’applicazione sicura ed efficace, ti
                            raccomandiamo di leggere i nostri consigli generali.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">PRENDI IL SOLE IN SICUREZZA</h2>
                        <p className="toggle__sub-title">
                          I prodotti di protezione solare PIZ BUIN<sup>®</sup>{" "}
                          ti offrono tutta la protezione di cui hai bisogno; che
                          cosa puoi fare per assicurarti di esporti al sole con
                          la massima sicurezza possibile?{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>Protezione solare</h2>
                          <p>
                            <strong>Come funzionano i filtri solari?</strong>
                          </p>
                          <p>
                            La tecnologia chiave usata in qualsiasi protezione
                            solare è il filtro UV. I filtri UV possono essere
                            chimici o fisici.
                          </p>
                          <p> </p>
                          <p> </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/f47522e78906ab93bd6746d5c4ffa028_f95.png"
                              alt="kts_img_640_chemical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <p> </p>
                          <p>
                            I filtri chimici agiscono assorbendo la luce
                            ultravioletta e convertendola in raggi di lunghezza
                            d'onda superiore, meno dannosi per la pelle.
                          </p>
                          <p> </p>
                          <p> </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/3abb2aaf80e1f6d050098a8ca6618a7a_f96.png"
                              alt="kts_img_640_psysical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <p>
                            I filtri fisici riflettono, trasmettono o assorbono
                            parzialmente la luce e possono proteggere sia dalle
                            radiazioni UVA che UVB. I due filtri fisici più
                            comuni sono il diossido di titanio e l'ossido di
                            zinco.
                          </p>
                          <h2>
                            Cosa significa fattore di protezione solare (SPF)?
                          </h2>
                          <p>
                            Il fattore di protezione solare (SPF) riportato sul
                            flacone del prodotto solare indica quanta protezione
                            dai raggi UVB viene offerta dalla protezione solare.
                          </p>
                          <p>
                            In generale, più alto è il numero SPF, maggiore è la
                            protezione, quindi un SPF 6 o 10 offre una
                            protezione bassa, un SPF 15, 20 o 25 offre una
                            protezione media e un SPF 30 o 50 offre una
                            protezione alta e un SPF 50+ offre una protezione
                            molto alta. Il fattore di protezione solare viene
                            misurato con un test standardizzato.
                          </p>
                          <p>
                            Il numero SPF indica quanto più a lungo è possibile
                            restare al sole prima di iniziare a scottarsi.
                            Funziona così: calcola il numero di minuti che
                            impiega la tua pelle mediamente per scottarsi al
                            sole senza alcuna protezione (diciamo 15 minuti).
                            Quindi, moltiplica il numero di minuti per il numero
                            SPF (in questo caso, fattore 6). 15 x 6 = 90, quindi
                            avrai 90 minuti prima di iniziare a scottarti. In
                            altre parole, maggiore è il fattore SPF, maggiore
                            sarà la protezione del tuo prodotto solare. Ricorda,
                            tuttavia, che si tratta di valori teorici. In
                            realtà, l'arco di tempo dipende anche da altri
                            fattori, tra cui il tuo fototipo e l'intensità UV
                            locale.
                          </p>
                          <p>
                            Per scegliere il fattore SPF adatto, dovrai tener
                            conto di diversi fattori. l più importante di questi
                            è il fototipo della pelle, che comprende il colore
                            della pelle prima dell'esposizione al sole, il
                            colore di occhi e capelli e altri fattori. Devi
                            anche considerare dove andrai in vacanza e in quale
                            periodo dell'anno.
                          </p>
                          <h2> </h2>
                          <h2>Che cos'è la fotostabilità?</h2>
                          <p>
                            Per proteggere la pelle, le protezioni solari usano
                            i filtri UV che assorbono le radiazioni solari.
                            Quando una protezione solare viene esposta a queste
                            radiazioni, queste possono alterare la struttura
                            chimica dei filtri UV, riducendone di fatto le
                            capacità protettive. Una protezione solare
                            "fotostabile" è in grado di resistere meglio agli
                            effetti dell'esposizione UV, mantenendo il suo
                            livello di protezione.
                          </p>
                          <p>
                            Tutti i prodotti di protezione solare PIZ BUIN
                            <sup>®</sup> offrono una protezione fotostabile.
                          </p>
                          <h2> </h2>
                          <h2>Cosa significa "protezione ad ampio spettro"?</h2>
                          <p>
                            Una protezione solare ad ampio spettro indica la
                            capacità di proteggere dagli effetti dannosi sia dei
                            raggi UVA (i raggi causa dell'invecchiamento
                            cutaneo) che UVB (i raggi causa delle scottature).
                            Per essere classificato come protezione ad ampio
                            spettro, un prodotto di protezione solare deve
                            assorbire o riflettere almeno il 90% dei raggi UV in
                            un intervallo di lunghezze d'onda dai 290 ai 400
                            nanometri (nm).
                          </p>
                          <p>
                            Tutti i prodotti di protezione solare PIZ BUIN
                            <sup>®</sup> offrono una protezione ad ampio
                            spettro.
                          </p>
                          <h2> </h2>
                          <h2>Resistenza all'acqua</h2>
                          <p>
                            È importante che la protezione solare sia resistente
                            all'acqua quando si nuota, dal momento che la
                            radiazione UV a mezzo metro dalla superficie
                            dell'acqua ha ancora il 40% di intensità rispetto
                            alla superficie. Inoltre, è necessario proteggere la
                            parte del corpo al di sopra della superficie, dal
                            momento che l'acqua riflette circa il 25% delle
                            radiazioni UV e può intensificarne gli effetti
                            dannosi.
                          </p>
                          <p>
                            Quasi tutti i prodotti di protezione solare PIZ BUIN
                            <sup>®</sup> hanno un livello di resistenza
                            all'acqua da normale a eccezionale, come il nostro
                            NUOVO PIZ BUIN Wet Skin<sup>®</sup> Spray Solare
                            Trasparente.
                          </p>
                          <p className="footNote">
                            *I seguenti prodotti non sono resistenti all‘acqua:
                            Allergy Spray Solare SPF 30, Bronze Latte
                            Abbronzante, Bronze Spray Abbronzanze, Bronze Olio
                            Secco Abbronzante, Mountain Stick Labbra SPF 30.
                          </p>
                          <h2> </h2>
                          <h2>Suggerimenti per la sicurezza al sole</h2>
                          <p>
                            Andare in vacanza significa spesso esporre la pelle
                            al sole per la prima volta dopo un lungo periodo
                            invernale di freddo e abiti pesanti. È importante
                            prestare massima attenzione alla pelle prima di
                            partire, per accertarsi che sia pronta al repentino
                            cambiamento.
                          </p>
                          <h3> </h3>
                          <p>
                            <strong>
                              Suggerimenti per un'applicazione sicura ed
                              efficace della protezione solare e un corretto
                              comportamento al sole:
                            </strong>
                          </p>
                          <ol>
                            <li>
                              <span>
                                Applica la protezione solare almeno 20 minuti
                                prima di esporti al sole per consentire il
                                massimo grado di assorbimento e protezione.
                              </span>
                            </li>
                            <li>
                              <span>
                                Assicurati di applicare la protezione solare in
                                misura adeguata. Una quantità insufficiente
                                riduce sensibilmente il livello di protezione.
                                Presta particolare attenzione alle zone più
                                sensibili, come orecchie, naso, spalle,
                                décolleté e collo.
                              </span>
                            </li>
                            <li>
                              <span>
                                Non trascurare le zone difficili da raggiungere
                                o che vengono facilmente dimenticate, come le
                                orecchie, i piedi e la parte superiore della
                                schiena.
                              </span>
                            </li>
                            <li>
                              <span>
                                Riapplica la protezione solare ogni 2 ore.
                                Applicala più frequentemente dopo aver nuotato,
                                sudato o esserti asciugato con l'asciugamano.{" "}
                              </span>
                            </li>
                            <li>
                              <span>
                                Evita l'intensa esposizione al sole nelle ore
                                centrali del giorno, dalle 11:00 alle 15:00, nei
                                mesi estivi o quando sei in vacanza in paesi
                                tropicali.
                              </span>
                            </li>
                            <li>
                              <span>
                                Se assumi dei farmaci, chiedi al tuo medico o al
                                farmacista se puoi esporti al sole senza
                                problemi. Alcuni farmaci possono rendere la
                                pelle più sensibile ai raggi del sole.
                              </span>
                            </li>
                            <li>
                              <span>
                                La sovraesposizione al sole può essere
                                pericolosa per la salute, quindi evita di
                                restare al sole troppo a lungo, anche se usi una
                                protezione solare.
                              </span>
                            </li>
                            <li>
                              <span>
                                Proteggi i bambini. I bambini sono
                                particolarmente vulnerabili agli effetti delle
                                radiazioni UV e spesso trascorrono più tempo
                                all'aperto rispetto agli adulti. I genitori
                                devono prestare particolare attenzione per
                                proteggerli dal sole, usando abbigliamento con
                                vestiti, cappelli, occhiali da sole e schermi
                                solari protettivi. Tieni i neonati e i bambini
                                più piccoli al riparo dalla luce diretta del
                                sole.
                              </span>
                            </li>
                          </ol>
                          <h2> </h2>
                          <h2>
                            <a name="skintypes" />
                            Tipi di pelle
                          </h2>
                          <p>
                            I diversi tipi di pelle reagiscono al sole in modo
                            diverso, quindi dal tuo tipo di pelle dipende quanto
                            a lungo puoi esporti al sole e quali tipi di
                            protezione solare devi usare.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">DOMANDE FREQUENTI</h2>
                        <p className="toggle__sub-title">
                          Cos’altro devo sapere sull'utilizzo dei prodotti di
                          protezione solare PIZ BUIN<sup>®</sup>?{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>
                            Usare una protezione solare mi impedirà di
                            abbronzarmi?
                          </h2>
                          <p>
                            Piuttosto è vero il contrario. Sebbene la pelle si
                            abbronzi più lentamente quando si usa una protezione
                            solare, l'abbronzatura ottenuta sarà migliore e più
                            duratura, dal momento che la protezione solare
                            impedirà alla pelle di scottarsi ed esfoliarsi.
                            Alcuni prodotti PIZ BUIN<sup>®</sup> contengono
                            anche ingredienti speciali che stimolano la
                            produzione naturale dei pigmenti abbronzanti della
                            pelle e la loro distribuzione uniforme.
                          </p>
                          <p> </p>
                          <h2>
                            Quanto dura una protezione solare prima di scadere?
                          </h2>
                          <p>
                            Dipende se il flacone è stato aperto o meno. Se non
                            è stato aperto, non ci sono problemi. Tutti i
                            prodotti PIZ BUIN<sup>®</sup> sono testati in base
                            alla normativa UE e hanno una durata di vita
                            superiore ai 30 mesi. Se il flacone è stato aperto
                            da oltre 12 mesi, dovrai acquistarne uno nuovo.
                          </p>
                          <p> </p>
                          <h2>
                            Posso diminuire il livello di SPF man mano che
                            trascorro più tempo al sole? Quanto rapidamente?
                          </h2>
                          <p>
                            È possibile diminuire il livello di protezione
                            gradualmente durante le vacanze, non appena la pelle
                            avrà prodotto il proprio sistema di difesa:
                            l'abbronzatura. Dopo qualche giorno di protezione
                            efficace, prova a passare da una protezione alta a
                            una protezione media. Tuttavia, ricorda che anche le
                            persone con un'abbronzatura naturale devono
                            applicare una protezione solare, dal momento che
                            l'abbronzatura non offre una sufficiente protezione
                            dall'esposizione ai raggi UV. Inoltre, ricorda che
                            il livello di SPF necessario dipende in larga misura
                            dal fototipo della pelle e dall'intensità UV locale.
                          </p>
                          <p>
                            Se non sei sicuro del livello SPF della tua
                            protezione solare, scegli sempre il livello più
                            alto.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\nif(document.location.hash != ''){\nvar anchor = document.location.hash;\n$(\"#container_thesun\").show();\nt = $(anchor);\n$('html,body').animate({scrollTop: t.offset().top},'slow');\n}\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
